import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@careos/react-ui/Table';
import type { SamplingSessionRequisition } from '@careos/toxicology-types';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useRequisitionColumns } from './requisition-columns';

interface RequisitionsDetailsProps {
  requisitions: SamplingSessionRequisition[];
}

export const RequisitionDetails = ({
  requisitions,
}: RequisitionsDetailsProps) => {
  const columns = useRequisitionColumns();
  const table = useReactTable({
    data: requisitions,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="overflow-x-auto">
      <Table className="bg-slate-50">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="hover:bg-slate-50">
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} className="hover:bg-slate-50">
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className=" text-sm text-gray-600">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default RequisitionDetails;
