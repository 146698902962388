import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';

export const AddTreatmentCenterRequestDtoSchema = z.object({
  name: nonEmptyString,
  organizationKey: nonEmptyString,
});
export const AddTreatmentCenterWithRequesterInfoRequestDtoSchema =
  AddTreatmentCenterRequestDtoSchema.merge(RequesterInfoSchema);
export type AddTreatmentCenterRequestDto = z.infer<
  typeof AddTreatmentCenterRequestDtoSchema
>;
export type AddTreatmentCenterWithRequesterInfoRequestDto = z.infer<
  typeof AddTreatmentCenterWithRequesterInfoRequestDtoSchema
>;
