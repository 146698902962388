import {
  isDoaActivityIdentifierWithSamplingKit,
  ToxicologyActivityIdentifier,
  type SamplingKitIdentifier,
} from '@careos/identifiers';
import type { GetPanelSizeWithAdditionalSubstancesResponseDto } from '@careos/toxicology-types';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

export const usePanels = ({
  activityDefinition,
  samplingKit,
  queryConfig,
}: {
  activityDefinition: ToxicologyActivityIdentifier;
  samplingKit?: SamplingKitIdentifier;
  queryConfig?: QueryConfig<typeof getPanelsQueryOptions>;
}) => {
  const { get } = useCustomFetch();

  function getPanelSizesWithAdditionalSubstances(
    activityDefinition: ToxicologyActivityIdentifier,
    samplingKit?: SamplingKitIdentifier,
  ) {
    return get<GetPanelSizeWithAdditionalSubstancesResponseDto>(
      'config/get-panels-with-additional-substances',
      {
        query: {
          activityDefinition,
          samplingKit,
        },
      },
    );
  }

  const enabled =
    (isDoaActivityIdentifierWithSamplingKit(activityDefinition) &&
      !!samplingKit) ||
    activityDefinition === ToxicologyActivityIdentifier.DOA_URINE_SAMPLING;

  function getPanelsQueryOptions(
    activityDefinition: ToxicologyActivityIdentifier,
    samplingKit?: SamplingKitIdentifier,
  ) {
    return queryOptions({
      queryKey: ['panels', activityDefinition, samplingKit],
      queryFn: () =>
        getPanelSizesWithAdditionalSubstances(activityDefinition, samplingKit),
      enabled,
      select: (data) => data.panels,
    });
  }

  return useQuery({
    ...getPanelsQueryOptions(activityDefinition, samplingKit),
    ...queryConfig,
  });
};
