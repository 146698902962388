import { nonEmptyString, type RequireAtLeastOne } from '@careos/types';
import { z } from 'zod';
import { OrganizationTypeSchema } from '../../../organization/organization-type';
import { RequesterInfoSchema } from '../../../requester-info';
import { RoleSchema } from './../role';

const UpdateUserRequestEditProps = z.object({
  email: z.string().trim().email().optional(),
  name: nonEmptyString
    .max(80, {
      message: 'User name should be less than 80 characters.',
    })
    .optional(),
  updatedRoles: z.array(RoleSchema).optional(),
  currentRoles: z.array(RoleSchema).optional(),
});

const UpdateUserRequestDtoBaseSchema = z.object({
  id: z.string().trim().uuid(),
  organizationKey: nonEmptyString,
  organizationType: OrganizationTypeSchema,
});

export const UpdateUserRequestDtoSchema = UpdateUserRequestDtoBaseSchema.merge(
  UpdateUserRequestEditProps,
);

export const UpdateUserWithRequesterInfoRequestDtoSchema =
  UpdateUserRequestDtoBaseSchema.merge(UpdateUserRequestEditProps)
    .merge(RequesterInfoSchema)
    .refine((val) => !!val.email || !!val.name || !!val.updatedRoles, {
      message: `At least one of the following properties must be included: email, name or roles`,
    });

type AtLeastOneUpdateUserRequestEditProps = RequireAtLeastOne<
  z.infer<typeof UpdateUserRequestEditProps>
>;

export type UpdateUserRequestDto = z.infer<
  typeof UpdateUserRequestDtoBaseSchema
> &
  AtLeastOneUpdateUserRequestEditProps;
export type UpdateUserWithRequesterInfoRequestDto = z.infer<
  typeof UpdateUserWithRequesterInfoRequestDtoSchema
> &
  AtLeastOneUpdateUserRequestEditProps;
