import { BankId, type BankIdResponse } from '@careos/react-ui/BankId';
import { Spinner } from '@careos/react-ui/Spinner';
import { useEffect, useState, memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useBankIdPolling,
  type BankIdInitiate,
} from '../hooks/use-bankid-polling';

type ScanQRProps = {
  transactionId: string;
  onCancel: () => void;
} & Pick<BankIdInitiate, 'onCompletion'>;

const ScanQR = ({ transactionId, onCancel, onCompletion }: ScanQRProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing.bankid.visibleText',
  });
  const { init, abort } = useBankIdPolling();
  const [bankIdData, setBankIdData] = useState<BankIdResponse>();
  const hasBeenInitiated = useRef(false);

  const visibleText = `## ${t('header')}\n --- \n${t('description', { transactionId: transactionId })} \n --- \n${t('footer')}`;

  const handleRetryPolling = () => {
    hasBeenInitiated.current = false;
    startPolling();
  };

  const startPolling = useCallback(() => {
    if (hasBeenInitiated.current) return;
    init({
      flowType: 'qr',
      signArgs: {
        transactionId,
        visibleText,
      },
      onError: (error) => {
        setBankIdData({
          status: 'error',
          ...error,
        });
      },
      onCompletion,
      onUpdate: setBankIdData,
    });
    hasBeenInitiated.current = true;
  }, [init, transactionId, visibleText, onCompletion]);

  useEffect(() => {
    startPolling();
    return () => {
      abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!bankIdData) return <Spinner size="lg" />;

  return (
    <BankId
      onTryAgain={handleRetryPolling}
      onCancel={onCancel}
      {...bankIdData}
    />
  );
};

export default memo(ScanQR);
