import type {
  GenerateRequisitionPdfResponseDto,
  SignRequisitionPdfDto,
} from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useSignRequisition = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof signRequisition>;
} = {}) => {
  const { post } = useCustomFetch();

  function signRequisition(dto: SignRequisitionPdfDto) {
    return post<GenerateRequisitionPdfResponseDto>(
      '/requisitions/sign-requisition',
      dto,
    );
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: signRequisition,
  });
};
