import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@careos/react-ui/AlertDialog';
import { Button } from '@careos/react-ui/Button';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmitConfirmation: () => void;
};

export const RequisitionSubmitModal = ({ onSubmitConfirmation }: Props) => {
  const { t } = useTranslation();
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button className="w-full py-8">
          {t('requisition_form.signing.collector_confirm_modal.dialog_trigger')}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t('requisition_form.signing.collector_confirm_modal.title')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t('requisition_form.signing.collector_confirm_modal.approval')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t('requisition_form.signing.collector_confirm_modal.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => onSubmitConfirmation()}>
            {t('requisition_form.signing.collector_confirm_modal.submit')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
