import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@careos/react-ui/Button';
import { PdfRender } from '@careos/react-ui/PdfRender';
import { Spinner } from '@careos/react-ui/Spinner';
import type { SigningCompletionData } from '@careos/types';
import { useParams } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BankIdQR } from '@/features/bank-id/components/bank-id-qr';
import { assertDefined } from '@/utils/assert-defined';

import { useCancelRequisitionRequest } from '../api/cancel-requisition-request';
import { useConfirmRequisition } from '../api/create-confirm-requisition';
import { useGenerateRequisition } from '../api/create-generate-requisition';
import { useInitiateRemoteSigning } from '../api/create-initiate-remote-signing';
import { useSignRequisition } from '../api/create-sign-requisition';
import { useSubmitRequisition } from '../api/create-submit-requisition';
import { useSignedRequisition } from '../api/get-signed-pdf-by-transaction-id';
import { getGenerateRequisitionRequestDto } from '../helpers/get-generate-requisition-request-dto';
import { getSignatureDimensions } from '../helpers/get-signature-dimensions';
import { useRemoteSignatureSse } from '../hooks/use-remote-signature-sse';

import { BackButton } from './back-button';
import { RequisitionCreated } from './requisition-created';
import type { RequisitionFormDonorInfo } from './requisition-form-donor-info';
import type { SamplingForm } from './requisition-form-sampling';
import { RequisitionFormSigningGoBackPrompt } from './requisition-form-signing-go-back-prompt';
import type { RequisitionFormTestInfo } from './requisition-form-test-info';
import { RequisitionSubmitModal } from './requisition-submit-modal';
import { SignaturePadModal } from './signature-pad-modal';

type Props = {
  testInfo: RequisitionFormTestInfo;
  donorInfo: RequisitionFormDonorInfo;
  samplingInfo: SamplingForm;
  onUpdateRequisitionStatus: (requisitionStatus: 'created') => void;
  onBack: () => void;
};

const RequisitionState = {
  UNSIGNED: 'unsigned',
  SIGNED: 'signed',
  CREATED: 'created',
} as const;

type RequisitionState =
  (typeof RequisitionState)[keyof typeof RequisitionState];

export const RequisitionFormSigning = ({
  onBack,
  onUpdateRequisitionStatus,
  ...props
}: Props) => {
  // INFO: creating a stable reference to prevent useEffect infinite loop
  const propsRef = useRef(props);
  const { sessionId } = useParams({
    from: '/_authenticated/sessions/$sessionId',
  });
  const { user } = useAuth0();
  const { i18n, t } = useTranslation();
  const locale = i18n.language === 'en' ? 'en-US' : 'sv-SE';
  const [isGoBackDialogOpen, setIsGoBackDialogOpen] = useState(false);

  // INFO: This is a workaround to prevent the cleanup function from running on initial state changes
  const requisitionState = useRef<RequisitionState>();

  const [requisition, setRequisition] = useState<{
    pdf: string;
    type: RequisitionState;
    transactionId: string;
    donorPassword?: string;
  }>();

  // INFO: Destructing here to convince eslint that we are using a stable reference for the useEffect dependency array
  const {
    mutate: generateRequisitionMutationMutate,
    data: generateRequisitionMutationData,
    isPending: generateRequisitionMutationIsPending,
  } = useGenerateRequisition({
    mutationConfig: {
      onSuccess: ({ pdf, transactionId }) => {
        requisitionState.current = RequisitionState.UNSIGNED;
        setRequisition({
          pdf,
          transactionId,
          type: RequisitionState.UNSIGNED,
        });
      },
    },
  });

  const { mutate: cancelRequisitionRequest } = useCancelRequisitionRequest();

  const {
    mutate: signRequisitionMutation,
    isPending: signRequisitionMutationIsPending,
  } = useSignRequisition({
    mutationConfig: {
      onSuccess: ({ pdf, transactionId }) => {
        requisitionState.current = RequisitionState.SIGNED;
        setRequisition({
          pdf,
          transactionId,
          type: RequisitionState.SIGNED,
        });
      },
    },
  });

  const submitRequisitionMutation = useSubmitRequisition({
    mutationConfig: {
      onSuccess: (data) => {
        setRequisition((current) => ({
          ...assertDefined(current),
          donorPassword: data.donorPassword,
        }));
        confirmRequisitionMutation.mutate({
          collectorConfirmationText: t(
            'requisition_form.signing.collector_confirm_modal.approval',
          ),
          requisitionId: data.requisitionId,
        });
      },
    },
  });

  const confirmRequisitionMutation = useConfirmRequisition({
    mutationConfig: {
      onSuccess: () => {
        requisitionState.current = RequisitionState.CREATED;
        setRequisition((current) => ({
          ...assertDefined(current),
          type: RequisitionState.CREATED,
        }));
        onUpdateRequisitionStatus('created');
      },
    },
  });

  const createInitiateRemoteSigningMutation = useInitiateRemoteSigning();
  const { sseData } = useRemoteSignatureSse(
    createInitiateRemoteSigningMutation.data?.transactionId,
  );

  useEffect(() => {
    if (!user) return;

    const dto = getGenerateRequisitionRequestDto({
      sessionId,
      ...propsRef.current,
      user,
      locale,
    });

    generateRequisitionMutationMutate(dto);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- We do not want this effect to trigger when the user reference or locale changes
  }, [generateRequisitionMutationMutate, sessionId]);

  const isPending =
    generateRequisitionMutationIsPending || signRequisitionMutationIsPending;

  const handleSubmitSignature = async (signature: string) => {
    signRequisitionMutation({
      transactionId: assertDefined(
        generateRequisitionMutationData?.transactionId,
      ),
      locale,
      signature: {
        dimensions: await getSignatureDimensions(signature),
        signatureData: signature,
        signatureType: 'HANDWRITTEN',
        signingDate: new Date().toISOString(),
      },
      signeeFullName: `${props.donorInfo.firstName} ${props.donorInfo.lastName}`,
    });
  };

  const handleSubmitBankIdSignature = (
    completionData: SigningCompletionData,
  ) => {
    const { signature, completionDate, user } = completionData;
    signRequisitionMutation({
      transactionId: assertDefined(
        generateRequisitionMutationData?.transactionId,
      ),
      locale,
      signature: {
        signatureData: signature,
        signatureType: 'BANKID',
        signingDate: completionDate,
      },
      signeeFullName: user.name,
      signeePersonalNumber: user.personalNumber,
    });
  };

  const handleSubmitConfirmation = () => {
    submitRequisitionMutation.mutate({
      transactionId: assertDefined(requisition?.transactionId),
    });
  };

  const { data: signedReq } = useSignedRequisition({
    transactionId: sseData?.transactionId,
    queryConfig: {
      enabled: !!sseData?.transactionId && sseData.status === 'DONOR_SIGNED',
    },
  });

  useEffect(() => {
    if (signedReq) {
      requisitionState.current = RequisitionState.SIGNED;
      setRequisition({
        pdf: signedReq,
        type: RequisitionState.SIGNED,
        transactionId: assertDefined(sseData?.transactionId),
      });
    }
  }, [signedReq, sseData?.transactionId]);

  useEffect(() => {
    return () => {
      // INFO: When leaving, only cancel the requisition if it is still in the initial state (not signed or submitted)
      if (
        requisition?.transactionId &&
        requisitionState.current === RequisitionState.UNSIGNED
      ) {
        cancelRequisitionRequest(requisition.transactionId);
      }
    };
  }, [requisition, cancelRequisitionRequest]);

  return (
    <div className="flex w-[800px] flex-col gap-y-4">
      {isPending && (
        <div className="flex h-96 items-center justify-center">
          <Spinner size="md" />
        </div>
      )}
      {requisition?.type === RequisitionState.UNSIGNED && (
        <>
          <PdfRender pdfSource={requisition.pdf} />
          <div className="flex gap-x-4">
            {createInitiateRemoteSigningMutation.isSuccess ? (
              <>
                <div className="w-full rounded-md border border-green-500 p-4 text-center text-green-800">
                  {`${t('requisition_form.signing.otp')}: ${createInitiateRemoteSigningMutation.data.pinCode}`}
                </div>
              </>
            ) : (
              <>
                <Button
                  className="w-full py-8"
                  onClick={() =>
                    createInitiateRemoteSigningMutation.mutate({
                      transactionId: requisition.transactionId,
                    })
                  }
                >
                  {t('requisition_form.signing.start_remote_signing')}
                </Button>
                <BankIdQR
                  onCompletion={handleSubmitBankIdSignature}
                  transactionId={requisition.transactionId}
                />
                <SignaturePadModal
                  onSubmitSignature={handleSubmitSignature}
                  loading={isPending}
                />
              </>
            )}
          </div>
        </>
      )}

      {requisition?.type === RequisitionState.SIGNED && (
        <>
          {<PdfRender pdfSource={requisition.pdf} />}
          <RequisitionSubmitModal
            onSubmitConfirmation={handleSubmitConfirmation}
          />
        </>
      )}
      {requisition?.type === RequisitionState.CREATED &&
        requisition?.donorPassword && (
          <RequisitionCreated
            donorPassword={requisition.donorPassword}
            pdf={requisition.pdf}
          />
        )}
      {requisition?.type !== RequisitionState.CREATED && (
        <BackButton
          onClick={() => setIsGoBackDialogOpen(true)}
          className="w-fit"
        />
      )}
      <RequisitionFormSigningGoBackPrompt
        isOpen={isGoBackDialogOpen}
        onOpenChange={setIsGoBackDialogOpen}
        onAction={onBack}
      />
    </div>
  );
};
