import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { OrganizationTypeSchema } from '../../../organization/organization-type';
import { RequesterInfoSchema } from '../../../requester-info';
import { RoleSchema } from '../role';

export const AddUserRequestDtoSchema = z.object({
  email: z.string().trim().email(),
  name: nonEmptyString.max(80, {
    message: 'User name should be less than 80 characters.',
  }),
  organizationKey: nonEmptyString,
  organizationType: OrganizationTypeSchema,
  roles: z.array(RoleSchema).nonempty(),
});
export const AddUserWithRequesterInfoRequestDtoSchema =
  AddUserRequestDtoSchema.merge(RequesterInfoSchema);

export type AddUserRequestDto = z.infer<typeof AddUserRequestDtoSchema>;
export type AddUserWithRequesterInfoRequestDto = z.infer<
  typeof AddUserWithRequesterInfoRequestDtoSchema
>;
