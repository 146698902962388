import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useCheckBarcode = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof checkBarcode>;
} = {}) => {
  const { post } = useCustomFetch();

  function checkBarcode(barcode: string) {
    return post('/requisitions/check-barcode', {
      barcode,
    });
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: checkBarcode,
  });
};
