import type {
  GenerateRandomNumberSeriesRequestDto,
  GenerateRandomNumberSeriesResponseDto,
} from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import { type MutationConfig } from '@/lib/react-query';

export const useRandomSamplingNumbers = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof createSamplingNumbersSeries>;
} = {}) => {
  const { post } = useCustomFetch();

  function createSamplingNumbersSeries(
    body: GenerateRandomNumberSeriesRequestDto,
  ) {
    return post<GenerateRandomNumberSeriesResponseDto>(
      'randomizer/generate-random-number-series',
      body,
    );
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: createSamplingNumbersSeries,
  });
};
