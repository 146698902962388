import { Button } from '@careos/react-ui/Button';
import { Label } from '@careos/react-ui/Label';
import { PdfRender } from '@careos/react-ui/PdfRender';
import { UserPen } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  donorPassword: string;
  pdf: string;
};
export const RequisitionCreated = ({ donorPassword, pdf }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.created',
  });
  const [isShowingRequisition, setIsShowingRequisition] = useState(false);
  return (
    <div className="flex w-full max-w-[800px] flex-col gap-y-4">
      <div>
        <Label className="text-md">{t('requisition_password')}</Label>
        <p className="rounded bg-slate-100 p-1 text-center font-mono font-semibold">
          {donorPassword}
        </p>
      </div>
      <div className="flex gap-4 rounded bg-slate-100 p-4">
        <UserPen size={44} className="text-slate-900" />
        <p>{t('donor_note_password')}</p>
      </div>
      <Button
        size="lg"
        variant="outline"
        onClick={() => setIsShowingRequisition((current) => !current)}
      >
        {isShowingRequisition ? t('hide_details') : t('show_details')}
      </Button>
      <Button size="lg" onClick={() => window.location.reload()}>
        {t('create_new_requisition')}
      </Button>
      <div className={isShowingRequisition ? 'block' : 'hidden'}>
        <PdfRender pdfSource={pdf} />
      </div>
    </div>
  );
};
