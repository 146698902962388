import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';

export const AddCustomerRequestDtoSchema = z.object({
  name: z.string().trim().min(3),
});
export const AddCustomerWithRequesterInfoRequestDtoSchema =
  AddCustomerRequestDtoSchema.merge(RequesterInfoSchema);

export type AddCustomerRequestDto = z.infer<typeof AddCustomerRequestDtoSchema>;
export type AddCustomerWithRequesterInfoRequestDto = z.infer<
  typeof AddCustomerWithRequesterInfoRequestDtoSchema
>;
