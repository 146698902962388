import type { ConfirmRequisitionRequestDto } from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useConfirmRequisition = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof confirmRequisition>;
} = {}) => {
  const { post } = useCustomFetch();

  function confirmRequisition(dto: ConfirmRequisitionRequestDto) {
    return post('/requisitions/confirm-requisition', dto);
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: confirmRequisition,
  });
};
