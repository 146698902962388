import { z } from 'zod';

export const ExpectedCgmFollowUpAnalysisTextCommentSchema = z.enum(['0']);
export type ExpectedCgmFollowUpAnalysisTextComment = z.infer<
  typeof ExpectedCgmFollowUpAnalysisTextCommentSchema
>;

export const AbandonedCgmFollowUpAnalysisTextCommentSchema = z.enum([
  '1',
  '2',
  '3',
]);
export type AbandonedCgmFollowUpAnalysisTextComment = z.infer<
  typeof AbandonedCgmFollowUpAnalysisTextCommentSchema
>;

export const CgmFollowUpAnalysisTextCommentSchema = z.enum([
  ...ExpectedCgmFollowUpAnalysisTextCommentSchema.options,
  ...AbandonedCgmFollowUpAnalysisTextCommentSchema.options,
]);
export type CgmFollowUpAnalysisTextComment = z.infer<
  typeof CgmFollowUpAnalysisTextCommentSchema
>;

export const isExpectedCgmFollowUpAnalysisTextComment = (
  value: unknown,
): value is ExpectedCgmFollowUpAnalysisTextComment =>
  ExpectedCgmFollowUpAnalysisTextCommentSchema.safeParse(value).success;

export const isAbandonedCgmFollowUpAnalysisTextComment = (
  value: unknown,
): value is AbandonedCgmFollowUpAnalysisTextComment =>
  AbandonedCgmFollowUpAnalysisTextCommentSchema.safeParse(value).success;
