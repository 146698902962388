import type { GetSamplingSessionByIdResponseDto } from '@careos/toxicology-types';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

export const useSamplingSession = ({
  sessionId,
  queryConfig,
}: {
  sessionId: string;
  queryConfig?: QueryConfig<typeof getSessionsQueryOptions>;
}) => {
  const { get } = useCustomFetch();

  function getSessionById(sessionId: string) {
    return get<GetSamplingSessionByIdResponseDto>(
      `/sampling-sessions/get-sampling-session/${sessionId}`,
    );
  }

  function getSessionsQueryOptions(sessionId: string) {
    return queryOptions({
      queryKey: ['sampling-session', sessionId],
      queryFn: () => getSessionById(sessionId),
    });
  }

  return useQuery({
    ...getSessionsQueryOptions(sessionId),
    ...queryConfig,
  });
};
