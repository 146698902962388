import {
  TestType,
  type GetEmployersAndEnabledTestInfoResponseDto,
} from '@careos/organization-api-types';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

export type CollectionOrderOptions = NonNullable<
  ReturnType<typeof useOrderOptions>['data']
>;

const selectOrderOptions = (
  data: GetEmployersAndEnabledTestInfoResponseDto,
) => {
  const enabledTestTypes = data.testInfo.map((it) => it.testType);
  const doaTestInfo = data.testInfo.find((it) => it.testType === TestType.DoA);

  return {
    employers: data.employers,
    enabledTestTypes,
    enabledSampleTypeAndKits: doaTestInfo?.sampleTypeAndEnabledKits || [],
  };
};

export const useOrderOptions = ({
  queryConfig,
}: {
  queryConfig?: QueryConfig<typeof getSessionsQueryOptions>;
} = {}) => {
  const { get } = useCustomFetch();

  function getOrderOptions() {
    return get<GetEmployersAndEnabledTestInfoResponseDto>(
      'config/get-order-options',
    );
  }

  function getSessionsQueryOptions() {
    return queryOptions({
      queryKey: ['order-options'],
      queryFn: () => getOrderOptions(),
    });
  }

  return useQuery({
    ...getSessionsQueryOptions(),
    ...queryConfig,
    select: (data) => selectOrderOptions(data),
  });
};
