import { queryOptions, useQuery } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

export const useSignedRequisition = ({
  transactionId,
  queryConfig,
}: {
  transactionId?: string;
  queryConfig?: QueryConfig<typeof getSignedRequisitionByTransactionIdOptions>;
}) => {
  const { get } = useCustomFetch();

  function getSignedRequisitionByTransactionId(transactionId?: string) {
    return get<string>(
      `requisitions/get-signed-pdf-by-transaction-id/${transactionId}`,
    );
  }

  function getSignedRequisitionByTransactionIdOptions(transactionId?: string) {
    return queryOptions({
      queryKey: ['signed-pdf', transactionId],
      queryFn: () => getSignedRequisitionByTransactionId(transactionId),
    });
  }

  return useQuery({
    ...getSignedRequisitionByTransactionIdOptions(transactionId),
    ...queryConfig,
  });
};
