import { Badge } from '@careos/react-ui/Badge';
import { Button } from '@careos/react-ui/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@careos/react-ui/DropdownMenu';
import {
  OrderReason,
  type GetSamplingSessionsResponseDto,
  type SamplingSessionStatus,
} from '@careos/toxicology-types';
import { Link } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { t } from 'i18next';
import { MoreHorizontal } from 'lucide-react';

import i18n from '@/i18n';

const columnHelper =
  createColumnHelper<
    GetSamplingSessionsResponseDto['samplingSessions'][number]
  >();

type SessionColumnProps = {
  onStartSession: (sessionId: string) => void;
  onFinishSession: (sessionId: string) => void;
  onGenerateNumbers: () => void;
};

const getStatusBadgeColor = (status: SamplingSessionStatus) => {
  switch (status) {
    case 'requested':
      return 'text-[#4031c6] bg-[#a89ff5]';
    case 'started':
      return 'text-[#7E6824] bg-[#FFF0C3]';
    case 'finished':
    case 'reported':
      return 'text-[#7b7b7b] bg-[#CDCDCD]';
    default:
      return 'bg-red-500';
  }
};

export const getSessionColumns = ({
  onStartSession,
  onFinishSession,
  onGenerateNumbers,
}: SessionColumnProps) => [
  columnHelper.accessor('samplingDate', {
    header: t('table.should_sample_at.header'),
    cell: (prop) => format(new Date(prop.getValue()), 'yyyy-MM-dd'),
  }),
  columnHelper.accessor('employer.name', {
    header: t('table.employer.header'),
  }),
  columnHelper.accessor('sessionId', {
    header: t('table.session_id.header'),
    cell: (prop) => prop.getValue().slice(0, 6),
  }),
  columnHelper.accessor('samplingDetails', {
    header: t('table.test_type.header'),
    cell: (prop) =>
      prop
        .getValue()
        .map((detail) => detail.testType)
        .join(', '),
  }),
  columnHelper.accessor('sampleCount', {
    header: t('table.sample_count.header'),
  }),

  columnHelper.accessor('reasonForTesting', {
    header: t('table.reason.header'),
    cell: (prop) => {
      const reasonValue = prop.getValue();
      const translation = t(`table.reason.${reasonValue}`);
      return translation;
    },
  }),
  columnHelper.accessor('status', {
    header: t('table.status.header'),
    cell: ({ getValue }) => {
      const color = getStatusBadgeColor(getValue());
      return (
        <Badge className={`font-bold ${color}`} variant={'static'}>
          {i18n.t(`table.status.session.${getValue()}`).toUpperCase()}
        </Badge>
      );
    },
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => {
      const sessionId = row.getValue<string>('sessionId');
      const reason = row.getValue<OrderReason>('reasonForTesting');
      const status = row.getValue<SamplingSessionStatus>('status');
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="relative size-10 p-0">
              <span className="sr-only">
                {t('table.actions.open_menu_sr_only')}
              </span>

              <MoreHorizontal className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>
              {t('table.actions.menu_label')}
            </DropdownMenuLabel>

            <DropdownMenuSeparator />
            {reason === 'random_selection' && (
              <DropdownMenuItem onClick={onGenerateNumbers}>
                {t('table.actions.generate_numbers')}
              </DropdownMenuItem>
            )}
            {status === 'requested' && (
              <DropdownMenuItem onClick={() => onStartSession(sessionId)}>
                {t('table.actions.start_sampling')}
              </DropdownMenuItem>
            )}
            {status === 'started' && (
              <>
                <DropdownMenuItem asChild>
                  <Link to="/sessions/$sessionId" params={{ sessionId }}>
                    {t('table.actions.resume_sampling')}
                  </Link>
                </DropdownMenuItem>

                <DropdownMenuItem onClick={() => onFinishSession(sessionId)}>
                  {t('table.actions.end_sampling')}
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  }),
];
