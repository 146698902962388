import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from '@tanstack/react-router';
import type { FetchOptions, FetchRequest } from 'ofetch';

import { baseFetch } from '@/lib/api-client';
import { isInvalidGrantError, isLoginRequiredError } from '@/utils/api.utils';

export const useCustomFetch = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const fetchWithAccessToken = async (
    request: FetchRequest,
    fetchOptions?: FetchOptions,
  ) => {
    const res = await baseFetch(request, {
      async onRequest({ options }) {
        try {
          const accessToken = await getAccessTokenSilently();

          options.headers = {
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
          };
        } catch (error) {
          // Happens when the user is not authenticated
          if (isLoginRequiredError(error)) {
            navigate({ to: '/' });
            return;
          }
          // Happens when the user's refresh token is invalid or expired
          if (isInvalidGrantError(error)) {
            loginWithRedirect({
              appState: { returnTo: window.location.origin },
            });
            return;
          }
          throw error;
        }
      },
      ...fetchOptions,
    });
    return res;
  };

  const get = <T>(
    request: FetchRequest,
    fetchOptions?: FetchOptions,
  ): Promise<T> => fetchWithAccessToken(request, fetchOptions);

  const post = <T>(
    request: FetchRequest,
    body: FetchOptions['body'],
  ): Promise<T> => fetchWithAccessToken(request, { method: 'POST', body });

  // TODO: Make naming consistent. 'delete' is not allowed as a variable declaration name.
  const DELETE = <T>(request: FetchRequest): Promise<T> =>
    fetchWithAccessToken(request, { method: 'DELETE' });

  return {
    get,
    post,
    DELETE,
  };
};
