import type { IsTransactionValidResponseDto } from '@careos/toxicology-types';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { useCustomFetchPublic } from '@/hooks/custom-fetch-public';
import type { QueryConfig } from '@/lib/react-query';

export const useDonorTransactionIsValid = ({
  transactionId,
  queryConfig,
}: {
  transactionId: string;
  queryConfig?: QueryConfig<typeof getDonorIsTransactionValidOptions>;
}) => {
  const { get } = useCustomFetchPublic();

  function donorIsTransactionValid(transactionId: string) {
    return get<IsTransactionValidResponseDto>(
      `requisitions/donor-is-transaction-valid/${transactionId}`,
    );
  }

  function getDonorIsTransactionValidOptions(transactionId: string) {
    return queryOptions({
      queryKey: ['transaction-valid', transactionId],
      queryFn: () => donorIsTransactionValid(transactionId),
    });
  }

  return useQuery({
    ...getDonorIsTransactionValidOptions(transactionId),
    ...queryConfig,
  });
};
