import { z } from 'zod';
import { DocumentStatusSchema } from './document-status';
import { nonEmptyString } from '@careos/types';

const UserReadsSchema = z.object({
  isReadByUser: z.boolean(),
});

export const DocumentMetadataSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  status: DocumentStatusSchema,
  version: z.number(),
  fileNamePrefix: nonEmptyString,
  updatedAt: z.string(),
});
export type DocumentMetadataDto = z.infer<typeof DocumentMetadataSchema>;

export const DocumentMetadataWithUserReadsSchema =
  DocumentMetadataSchema.merge(UserReadsSchema);
export type DocumentMetadataWithUserReadsDto = z.infer<
  typeof DocumentMetadataWithUserReadsSchema
>;

// ------------

export const DocumentSchema = DocumentMetadataSchema.merge(
  z.object({
    content: z.string().min(1),
  }),
);
export type DocumentDto = z.infer<typeof DocumentSchema>;

export const DocumentWithUserReadsSchema =
  DocumentSchema.merge(UserReadsSchema);
export type DocumentWithUserReadsDto = z.infer<
  typeof DocumentWithUserReadsSchema
>;
