import { z } from 'zod';
import { DocumentWithUserReadsSchema } from '.';
import {
  DocumentMetadataSchema,
  DocumentMetadataWithUserReadsSchema,
} from './document';

export const DocumentsMetadataResponseSchema = z.object({
  documents: DocumentMetadataSchema.array(),
});
export type DocumentsMetadataResponseDto = z.infer<
  typeof DocumentsMetadataResponseSchema
>;

export const DocumentsMetadataWithUserReadsResponseSchema = z.object({
  documents: DocumentMetadataWithUserReadsSchema.array(),
});
export type DocumentsMetadataWithUserReadsResponseDto = z.infer<
  typeof DocumentsMetadataWithUserReadsResponseSchema
>;

// -----------

export const DocumentsWithUserReadsResponseSchema = z.object({
  documents: DocumentWithUserReadsSchema.array(),
});

export type DocumentsWithUserReadsResponseDto = z.infer<
  typeof DocumentsWithUserReadsResponseSchema
>;
