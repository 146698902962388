import { useAuth0 } from '@auth0/auth0-react';
import { AuthProvider } from '@careos/react-ui/AuthProvider';
import { ErrorBoundary, ErrorFallback } from '@careos/react-ui/ErrorBoundary';
import { ThemeProvider } from '@careos/react-ui/ThemeProvider';
import { initializeSentry } from '@careos/react-ui/utils';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import { queryClient } from '../lib/react-query';

import { routeTree } from './routeTree.gen';

import '../i18n';

// Create a new router instance
const router = createRouter({
  routeTree,
  notFoundMode: 'root',
  context: { auth: undefined! },
  defaultErrorComponent: ({ error, reset, info }) => (
    <ErrorFallback
      error={error}
      resetError={reset}
      componentStack={info?.componentStack}
    />
  ),
  defaultNotFoundComponent: NotFoundComponent,
});

initializeSentry(router);

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function NotFoundComponent() {
  const { t } = useTranslation('translation');
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <img
        src="/abclabs.svg"
        alt="abclogo"
        className="m-6 h-36 rounded-md p-4"
      />
      <p className="text-4xl">{t('nav.not_found')}</p>
    </div>
  );
}

function InnerApp() {
  const auth = useAuth0();
  return <RouterProvider router={router} context={{ auth }} />;
}

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider>
            <InnerApp />
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
