import type { DonorSignRequisitionRequestDto } from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetchPublic } from '@/hooks/custom-fetch-public';
import type { MutationConfig } from '@/lib/react-query';

export const useDonorSignRequisition = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof donorSignRequisition>;
} = {}) => {
  const { post } = useCustomFetchPublic();

  function donorSignRequisition(dto: DonorSignRequisitionRequestDto) {
    return post<void>('/requisitions/donor-sign-requisition', dto);
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: donorSignRequisition,
  });
};
