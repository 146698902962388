import type {
  CreateOrderResponseDto,
  SubmitOrderRequestDto,
} from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useSubmitRequisition = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof submitRequisition>;
} = {}) => {
  const { post } = useCustomFetch();

  function submitRequisition(dto: SubmitOrderRequestDto) {
    return post<CreateOrderResponseDto>('/requisitions/submit', dto);
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: submitRequisition,
  });
};
