import { CheckCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const DonorSignSuccess = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing.donor',
  });
  return (
    <div className="flex h-dvh items-center justify-center px-2">
      <div className="rounded-xl bg-white p-4">
        <h1>{t('success')}</h1>
        <p>{t('success_await_instructions')}</p>
        <CheckCircle size={64} className="mx-auto text-green-600" />
      </div>
    </div>
  );
};
