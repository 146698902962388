import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';
import { OrganizationTypeSchema } from '../../../organization-type';

export const UpdateTreatmentCenterRequestDtoSchema = z.object({
  treatmentCenterKey: nonEmptyString,
  newTreatmentCenterName: nonEmptyString,
  organizationKey: nonEmptyString,
  organizationType: OrganizationTypeSchema,
});
export const UpdateTreatmentCenterWithRequesterInfoRequestDtoSchema =
  UpdateTreatmentCenterRequestDtoSchema.merge(RequesterInfoSchema);
export type UpdateTreatmentCenterRequestDto = z.infer<
  typeof UpdateTreatmentCenterRequestDtoSchema
>;
export type UpdateTreatmentCenterWithRequesterInfoRequestDto = z.infer<
  typeof UpdateTreatmentCenterWithRequesterInfoRequestDtoSchema
>;
