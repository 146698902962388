import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';

export const AddEmployerRequestDtoSchema = z.object({
  name: nonEmptyString,
  organizationKey: nonEmptyString,
});

export const AddEmployerWithRequesterInfoRequestDtoSchema =
  AddEmployerRequestDtoSchema.merge(RequesterInfoSchema);

export type AddEmployerRequestDto = z.infer<typeof AddEmployerRequestDtoSchema>;
export type AddEmployerWithRequesterInfoRequestDto = z.infer<
  typeof AddEmployerWithRequesterInfoRequestDtoSchema
>;
