import { GenericError } from '@auth0/auth0-react';

const AUTH0_ERROR_LOGIN_REQUIRED = 'login_required';
const AUTH0_ERROR_INVALID_GRANT = 'invalid_grant';
const AUTH0_ERROR_DESCRIPTION_INVALID_REFRESH_TOKEN =
  'Unknown or invalid refresh token.';
export const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred.';

// Utility functions for error checks
export const isLoginRequiredError = (error: unknown): boolean =>
  error instanceof GenericError && error.error === AUTH0_ERROR_LOGIN_REQUIRED;

export const isInvalidGrantError = (error: unknown): boolean =>
  error instanceof GenericError &&
  error.error === AUTH0_ERROR_INVALID_GRANT &&
  error.error_description === AUTH0_ERROR_DESCRIPTION_INVALID_REFRESH_TOKEN;
