import { SamplingKitIdentifierSchema } from '@careos/identifiers';
import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';
import { OrganizationTypeSchema } from '../../../organization-type';
import { isValidTestType, TestToOrderTypeSchema } from '../../../test-type';

export const UpdateOrganizationEnabledTestTypesRequestDtoSchema = z.object({
  enabledTestTypes: z.array(TestToOrderTypeSchema),
  enabledSamplingKits: z.array(SamplingKitIdentifierSchema),
  organizationKey: z.string(),
  organizationType: OrganizationTypeSchema,
});

export const UpdateOrganizationEnabledTestTypesWithRequesterInfoRequestDtoSchema =
  UpdateOrganizationEnabledTestTypesRequestDtoSchema.merge(
    RequesterInfoSchema,
  ).refine(
    (it) =>
      it.enabledTestTypes.every((testToOrderType) =>
        isValidTestType(it.organizationType, testToOrderType),
      ),
    () => ({ message: 'Invalid test type' }),
  );

export type UpdateOrganizationEnabledTestTypesRequestDto = z.infer<
  typeof UpdateOrganizationEnabledTestTypesRequestDtoSchema
>;
export type UpdateOrganizationEnabledTestTypesWithRequesterInfoRequestDto =
  z.infer<
    typeof UpdateOrganizationEnabledTestTypesWithRequesterInfoRequestDtoSchema
  >;
