import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context: { auth } }) => {
    try {
      await auth.getAccessTokenSilently();
    } catch {
      auth.loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  },
});
