import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { nonEmptyString } from '@careos/types';
import { z } from 'zod';

export const FileUploadRequestDtoSchema = z.object({
  content: z.string().base64().min(1),
  fileNamePrefix: nonEmptyString,
});

export class FileUploadRequestDto extends createSchemaClass(
  FileUploadRequestDtoSchema,
) {}
