import type { GetSamplingSessionsResponseDto } from '@careos/toxicology-types';
import { queryOptions, useQuery } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { QueryConfig } from '@/lib/react-query';

export const USE_SESSIONS_QUERY_KEY = 'sampling-sessions';
export const useSamplingSessions = ({
  queryConfig,
}: {
  queryConfig?: QueryConfig<typeof getSessionsQueryOptions>;
} = {}) => {
  const { get } = useCustomFetch();
  function getSessions() {
    return get<GetSamplingSessionsResponseDto>(
      '/sampling-sessions/get-sampling-sessions',
    );
  }

  function getSessionsQueryOptions() {
    return queryOptions({
      queryKey: [USE_SESSIONS_QUERY_KEY],
      queryFn: () => getSessions(),
    });
  }

  return useQuery({
    ...getSessionsQueryOptions(),
    ...queryConfig,
  });
};
