import { Auth0Provider } from '@auth0/auth0-react';

type A0ProviderProps = {
  children: React.ReactNode;
  /** Whether to throw an error or just log it when required environment variables are missing. */
  throwOnMissingEnv?: boolean;
};

/**
 * Auth0Provider wrapper that provides the necessary configuration for Auth0.
 * This component should be used at the root of the application, wrapping the entire app, preferably inside an ErrorBoundary.
 *
 * Required environment variables:
 * - VITE_AUTH0_DOMAIN
 * - VITE_AUTH0_CLIENT_ID
 * - VITE_AUTH0_CALLBACK_URL
 * - VITE_AUTH0_AUDIENCE
 *
 * Optional environment variables:
 * - VITE_AUTH0_ORGANIZATION_ID
 *
 * @param children - The children to render.
 * @param throwOnMissingEnv - Whether to throw an error or just log it when required environment variables are missing. Defaults to `true`. Recommended to keep as `true`.
 *
 * @example
 * ```tsx
 * <ErrorBoundary>
 *   <AuthProvider>
 *     <App />
 *   </AuthProvider>
 * </ErrorBoundary>
 * ```
 */
export const AuthProvider = ({
  children,
  throwOnMissingEnv = true,
}: A0ProviderProps) => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const redirectUri = import.meta.env.VITE_AUTH0_CALLBACK_URL;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
  const organization = import.meta.env.VITE_AUTH0_ORGANIZATION_ID;

  const requiredEnvVars = { domain, clientId, redirectUri, audience };

  const missingEnvVars = Object.entries(requiredEnvVars)
    .filter(([, value]) => !value)
    .map(([key]) => key);

  const message = `Missing required Auth0 environment variables: ${missingEnvVars.join(', ')}`;

  if (missingEnvVars.length > 0 && throwOnMissingEnv) {
    throw new Error(message);
  }

  if (missingEnvVars.length > 0) {
    console.error(message);
  }

  return (
    <Auth0Provider
      useRefreshTokens // Automatically adds offline_access scope to requests
      cacheLocation="localstorage" // Concluded as "good enough" for now
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        organization,
        audience,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
