import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';

export const UpdateSubdivisionRequestDtoSchema = z.object({
  subdivisionKey: nonEmptyString,
  newSubdivisionName: nonEmptyString,
  employerKey: nonEmptyString,
  organizationKey: nonEmptyString,
});
export const UpdateSubdivisionWithRequesterInfoRequestDtoSchema =
  UpdateSubdivisionRequestDtoSchema.merge(RequesterInfoSchema);
export type UpdateSubdivisionRequestDto = z.infer<
  typeof UpdateSubdivisionRequestDtoSchema
>;
export type UpdateSubdivisionWithRequesterInfoRequestDto = z.infer<
  typeof UpdateSubdivisionWithRequesterInfoRequestDtoSchema
>;
