import { useAuth0 } from '@auth0/auth0-react';
import type { RemoteSignatureEventMessageDto } from '@careos/toxicology-types';
import { useEffect, useState, useCallback, useRef } from 'react';

export const useRemoteSignatureSse = (transactionId: string | undefined) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [data, setData] = useState<RemoteSignatureEventMessageDto>();
  const eventSource = useRef<EventSource | null>(null);

  const setupSSE = useCallback(
    async (transactionId: string) => {
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch {
        // INFO: beware... refresh token needs to be decently long-lived
        loginWithRedirect({
          appState: {
            returnTo: '/',
          },
        });
        return;
      }

      eventSource.current = new EventSource(
        `${import.meta.env.VITE_COLLECTION_API_URL}/requisitions/subscribe/remote-signature/${transactionId}?token=${token}`,
      );

      eventSource.current.onmessage = (event) => {
        const newData = JSON.parse(event.data);
        if (newData.status === 'DONOR_SIGNED') {
          eventSource.current?.close();
        }

        setData(newData);
      };

      eventSource.current.onerror = (error) => {
        // eslint-disable-next-line no-console -- Temporary for testing purposes
        console.error(error);
      };
    },
    [getAccessTokenSilently, loginWithRedirect],
  );

  useEffect(() => {
    if (transactionId) {
      setupSSE(transactionId);
    }

    return () => {
      eventSource.current?.close();
    };
  }, [setupSSE, transactionId]);

  // INFO: Safari seems to have issues re-establishing the connection sometimes. Forcing a new connection when window is focused seems to resolve the issue.
  useEffect(() => {
    const handleFocus = () => {
      if (eventSource.current && transactionId) {
        eventSource.current.close();
        setupSSE(transactionId);
      }
    };

    window.addEventListener('focus', handleFocus);

    // INFO: When the requisition is signed, we are done with the SSE connection.
    if (data?.status === 'DONOR_SIGNED') {
      window.removeEventListener('focus', handleFocus);
    }

    return () => window.removeEventListener('focus', handleFocus);
  }, [setupSSE, transactionId, data?.status]);

  return {
    sseData: data,
  };
};
