import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { DocumentMetadataSchema, DocumentSchema } from './document';

export const CreateDocumentMetadataSchema = DocumentMetadataSchema.omit({
  id: true,
  updatedAt: true,
});
export class CreateDocumentMetadataRequestDto extends createSchemaClass(
  CreateDocumentMetadataSchema,
) {}
export const UpdateDocumentMetadataSchema = CreateDocumentMetadataSchema.omit({
  fileNamePrefix: true,
});
export class UpdateDocumentMetadataRequestDto extends createSchemaClass(
  UpdateDocumentMetadataSchema,
) {}

// ---------

export const CreateDocumentSchema = DocumentSchema.omit({
  id: true,
  updatedAt: true,
});
export class CreateDocumentRequestDto extends createSchemaClass(
  CreateDocumentSchema,
) {}
