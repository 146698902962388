import Personnummer from 'personnummer';

export const formatPersonalNumber = (personalNumber: string) => {
  const shouldFormat = personalNumber.length !== 13;
  if (personalNumber.length >= 10) {
    const isValidPersonalNumber = Personnummer.valid(personalNumber);

    if (isValidPersonalNumber && shouldFormat) {
      const parsedPersonalNumber = Personnummer.parse(personalNumber);

      const { fullYear, month, day, sep, num, check } = parsedPersonalNumber;

      const formattedPersonalNumber = `${fullYear}${month}${day}${sep}${num}${check}`;
      return formattedPersonalNumber;
    }
  }
};
