import { Spinner } from '@careos/react-ui/Spinner';
import { createFileRoute } from '@tanstack/react-router';

import { useOrderOptions } from '@/features/order/form/api/get-order-options';
import { useSamplingSession } from '@/features/order/form/api/get-sampling-session';
import { RequisitionForm } from '@/features/order/form/components/requisition-form';

export const Route = createFileRoute('/_authenticated/sessions/$sessionId')({
  component: SamplingSessionComponent,
});

function SamplingSessionComponent() {
  const { sessionId } = Route.useParams();
  const { data, isPending, error } = useSamplingSession({ sessionId });
  const {
    data: orderOptions,
    isPending: orderOptionsIsPending,
    error: orderOptionsError,
  } = useOrderOptions();

  if (isPending || orderOptionsIsPending) {
    return (
      <div className="flex h-96 items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  // Caught by error boundary
  if (error || orderOptionsError) {
    throw new Error(
      error?.message ||
        orderOptionsError?.message ||
        'An unknown error occurred',
    );
  }

  return (
    <div className="min-h-screen pt-8">
      <RequisitionForm
        {...data.samplingSession}
        collectionOrderOptions={orderOptions}
      />
    </div>
  );
}
