import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import { Checkbox } from '@careos/react-ui/Checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@careos/react-ui/Form';
import { Input } from '@careos/react-ui/Input';
import { PhoneInput } from '@careos/react-ui/PhoneInput';
import { Switch } from '@careos/react-ui/Switch';
import { nonEmptyString } from '@careos/types';
import { zodResolver } from '@hookform/resolvers/zod';
import Personnummer from 'personnummer';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

import { formatPersonalNumber } from '../helpers/format-personal-number';

import { BackButton } from './back-button';
import { NextButton } from './next-button';

// TODO: Make be validation stricter so we can reuse it for the form validation.
const FormSchema = z.object({
  personalIdentifier: z.discriminatedUnion('system', [
    z.object({
      system: z.literal(IdentifierSystem.PersonalIdentityNumber),
      value: z
        .string()
        .refine(Personnummer.valid, { message: 'Invalid personal number' }),
    }),
    z.object({
      system: z.literal(IdentifierSystem.PatientReference),
      value: nonEmptyString,
    }),
  ]),

  firstName: z.string().min(2),
  lastName: z.string().min(2),
  phoneNumber: z
    .string()
    .refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
  attester: z.discriminatedUnion('needsAttester', [
    z.object({
      needsAttester: z.literal(true),
      firstName: nonEmptyString,
      lastName: nonEmptyString,
      personalNumber: z
        .string()
        .refine(Personnummer.valid, { message: 'Invalid personal number' }),
    }),
    z.object({ needsAttester: z.literal(false) }),
  ]),
  collectorConfirm: z.boolean().refine((isNotConfirmed) => isNotConfirmed, {
    message: 'You need to confirm in order to proceed',
  }),
});

export type RequisitionFormDonorInfo = z.infer<typeof FormSchema>;

type Props = {
  onUpdate: (
    formVals: RequisitionFormDonorInfo,
    stepDirection: 'previous' | 'next',
  ) => void;
  defaultValues: RequisitionFormDonorInfo;
};

export const RequisitionFormDonorInfo = ({
  onUpdate,
  defaultValues,
}: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.donor_info',
  });

  const form = useForm<RequisitionFormDonorInfo>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  function onSubmit(values: RequisitionFormDonorInfo) {
    onUpdate(values, 'next');
  }

  function onGoBack() {
    onUpdate(form.getValues(), 'previous');
  }

  const watchPersonalIdentifier = form.watch('personalIdentifier');
  const watchNeedsAttester = form.watch('attester.needsAttester');

  const handlePersonalNumberFormatting = (value: string) => {
    if (watchPersonalIdentifier.system === IdentifierSystem.PatientReference) {
      return;
    }

    const formatted = formatPersonalNumber(value);
    if (formatted) {
      form.setValue('personalIdentifier.value', formatted, {
        shouldValidate: true,
      });
    }
  };

  return (
    <div className="min-w-[350px]">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col space-y-4"
        >
          <FormField
            control={form.control}
            name="personalIdentifier.system"
            render={({ field }) => (
              <FormItem className="flex flex-row items-baseline justify-between rounded-lg">
                <FormDescription>
                  {t('no_personal_number.description')}
                </FormDescription>

                <FormControl>
                  <Switch
                    checked={
                      field.value === IdentifierSystem.PersonalIdentityNumber
                    }
                    onCheckedChange={(val) => {
                      const newVal = val
                        ? IdentifierSystem.PersonalIdentityNumber
                        : IdentifierSystem.PatientReference;
                      field.onChange(newVal);
                      form.setValue('personalIdentifier.value', '');
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="personalIdentifier.value"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {watchPersonalIdentifier.system ===
                  IdentifierSystem.PersonalIdentityNumber
                    ? t('personal_number.label')
                    : t('personal_id.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    onChange={(val) => {
                      field.onChange(val.target.value);
                      handlePersonalNumberFormatting(val.target.value);
                    }}
                    placeholder={
                      watchPersonalIdentifier.system ===
                      IdentifierSystem.PersonalIdentityNumber
                        ? t('personal_number.placeholder')
                        : ''
                    }
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('first_name.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('last_name.label')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('phone_number.label')}</FormLabel>
                <FormControl>
                  <PhoneInput {...field} defaultCountry="SE" />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="attester.needsAttester"
            render={({ field }) => (
              <FormItem className="flex flex-row items-baseline justify-between rounded-lg">
                <FormDescription>{t('attester.switch.label')}</FormDescription>

                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={(val) => {
                      field.onChange(val);
                      form.setValue('attester', {
                        needsAttester: val,
                        firstName: '',
                        lastName: '',
                        personalNumber: '',
                      });
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          {watchNeedsAttester && (
            <>
              <FormField
                control={form.control}
                name="attester.firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('attester.first_name.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="attester.lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('attester.last_name.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="attester.personalNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('attester.personal_number.label')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(val) => {
                          field.onChange(val.target.value);

                          const formatted = formatPersonalNumber(
                            val.target.value,
                          );
                          if (formatted) {
                            form.setValue(
                              'attester.personalNumber',
                              formatted,
                              { shouldValidate: true },
                            );
                          }
                        }}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          <FormField
            control={form.control}
            name="collectorConfirm"
            render={({ field }) => (
              <FormItem>
                <div className="my-3 flex items-center gap-x-2 rounded-md bg-slate-50 px-4">
                  <FormControl>
                    <Checkbox
                      className="size-6 leading-none"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="leading-1 py-4">
                    {t('collector_confirm.label')}
                  </FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex justify-between">
            <BackButton onClick={onGoBack} />
            <NextButton className="w-1/2" />
          </div>
        </form>
      </Form>
    </div>
  );
};
