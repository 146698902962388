import { useState } from 'react';

const steps = [
  'test_info',
  'donor_info',
  'sampling',
  'signing',
  'created',
] as const;
export type Step = (typeof steps)[number];

export const useMultipleStepForm = () => {
  const [currentStep, setCurrentStep] = useState<Step>(steps[0]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const nextStep = () => {
    setCurrentStep(steps[currentStepIndex + 1]);
    setCurrentStepIndex((current) => current + 1);
  };

  const previousStep = () => {
    setCurrentStepIndex((current) => current - 1);
    setCurrentStep(steps[currentStepIndex - 1]);
  };

  return {
    nextStep,
    currentStep,
    previousStep,
  };
};
