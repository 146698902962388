import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { OrganizationTypeSchema } from '../../../organization/organization-type';
import { RequesterInfoSchema } from '../../../requester-info';

export const RemoveUsersFromOrganizationRequestDtoSchema = z.object({
  userDbIds: z.array(z.string().trim().uuid()),
  organizationKey: nonEmptyString,
  organizationType: OrganizationTypeSchema,
  userOrganizationRoleId: z.string().uuid().optional(),
});
export const RemoveUsersFromOrganizationWithRequesterInfoRequestDtoSchema =
  RemoveUsersFromOrganizationRequestDtoSchema.merge(RequesterInfoSchema);
export type RemoveUsersFromOrganizationRequestDto = z.infer<
  typeof RemoveUsersFromOrganizationRequestDtoSchema
>;
export type RemoveUsersFromOrganizationWithRequesterInfoRequestDto = z.infer<
  typeof RemoveUsersFromOrganizationWithRequesterInfoRequestDtoSchema
>;
