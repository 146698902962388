import type { InitiateBankidSigningRequestDto } from '@careos/toxicology-types';
import type {
  BankIdRequestErrorResponseDto,
  CheckResponseDto,
  SignResponseDto,
} from '@careos/types';

import { useCustomFetch } from '@/hooks/custom-fetch';

export const useCollectorApi = () => {
  const { post, DELETE } = useCustomFetch();

  const sign = (body: InitiateBankidSigningRequestDto) =>
    post<SignResponseDto | BankIdRequestErrorResponseDto>(
      '/requisitions/initiate-bankid-signing',
      body,
    );

  const check = (orderRef: string) =>
    post<CheckResponseDto | BankIdRequestErrorResponseDto>(
      `/requisitions/check-bankid-signing/${orderRef}`,
      {},
    );

  const cancel = (orderRef: string) =>
    DELETE<void | BankIdRequestErrorResponseDto>(
      `/requisitions/cancel-bankid-signing/${orderRef}`,
    );

  return {
    sign,
    check,
    cancel,
  };
};
