import type {
  DonorGetRequisitionByTransactionIdRequestDto,
  GenerateRequisitionPdfResponseDto,
} from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetchPublic } from '@/hooks/custom-fetch-public';
import type { MutationConfig } from '@/lib/react-query';

export const useDonorGetRequisition = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof donorGetRequisitionByTransactionId>;
} = {}) => {
  const { post } = useCustomFetchPublic();

  function donorGetRequisitionByTransactionId(
    dto: DonorGetRequisitionByTransactionIdRequestDto,
  ) {
    return post<GenerateRequisitionPdfResponseDto>(
      '/requisitions/donor-get-requisition-by-transaction-id',
      dto,
    );
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: donorGetRequisitionByTransactionId,
  });
};
