import { useEffect, useState } from 'react';
import QRLib from 'qrcode';

type QrCodeProps = {
  qrCode: string;
};

const qrCodeOptions = {
  width: 200,
  margin: 3,
  errorCorrectionLevel: 'L',
} as const;

export const QRCode = ({ qrCode }: QrCodeProps) => {
  const [qrImage, setQrImage] = useState<string>();

  useEffect(() => {
    QRLib.toDataURL(qrCode, qrCodeOptions)
      .then((url) => {
        setQrImage(url);
      })
      .catch(() => {
        setQrImage(undefined);
      });
  }, [qrCode]);

  if (!qrImage) {
    return null;
  }

  return <img src={qrImage} alt="qr" />;
};
