import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';

export const AddSubdivisionRequestDtoSchema = z.object({
  name: nonEmptyString,
  employerKey: nonEmptyString,
  organizationKey: nonEmptyString,
});
export const AddSubdivisionWithRequesterInfoRequestDtoSchema =
  AddSubdivisionRequestDtoSchema.merge(RequesterInfoSchema);

export type AddSubdivisionRequestDto = z.infer<
  typeof AddSubdivisionRequestDtoSchema
>;
export type AddSubdivisionWithRequesterInfoRequestDto = z.infer<
  typeof AddSubdivisionWithRequesterInfoRequestDtoSchema
>;
