import type {
  InitiateRemoteSigningRequestDto,
  InitiateRemoteSigningResponseDto,
} from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useInitiateRemoteSigning = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof initiateRemoteSigning>;
} = {}) => {
  const { post } = useCustomFetch();

  function initiateRemoteSigning(dto: InitiateRemoteSigningRequestDto) {
    return post<InitiateRemoteSigningResponseDto>(
      '/requisitions/initiate-remote-signing',
      dto,
    );
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: initiateRemoteSigning,
  });
};
