import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';
import { OrganizationTypeSchema } from '../../../organization-type';

export const AddOrganizationRequestDtoSchema = z.object({
  customerAbbreviation: z.string(),
  name: z.string().trim().min(3),
  type: OrganizationTypeSchema,
});
export const AddOrganizationWithRequesterInfoRequestDtoSchema =
  AddOrganizationRequestDtoSchema.merge(RequesterInfoSchema).extend({
    organizationKey: z.string(),
  });

export type AddOrganizationRequestDto = z.infer<
  typeof AddOrganizationRequestDtoSchema
>;
export type AddOrganizationWithRequesterInfoRequestDto = z.infer<
  typeof AddOrganizationWithRequesterInfoRequestDtoSchema
>;
