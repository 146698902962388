import { Button } from '@careos/react-ui/Button';
import { LoadingButton } from '@careos/react-ui/LoadingButton';
import { cn } from '@careos/react-ui/utils';
import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import _SignaturePad from 'signature_pad';

type SignaturePadProps = {
  onSubmitSignature: (signature: string) => void;
  /** Loading state to indicate if the signature is being submitted */
  loading?: boolean;
  className?: string;
};

const height = 250;
const maxWidth = 1200; // There are some quirks with the signature pad when the dimensions get too large
const widthPercentage = 0.95;
const widthInVw = `${widthPercentage * 100}vw`;

export const SignaturePad = ({
  onSubmitSignature,
  className,
  loading = false,
}: SignaturePadProps) => {
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const signaturePadRef = useRef<_SignaturePad | null>(null);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [signature, setSignature] = useState<string | null>(null);

  /**
   * Resize the canvas to match the size of the container.
   * Accomodates for high DPI displays, such as Retina, else the canvas image will be blurry.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
   * @see https://github.com/szimek/signature_pad?tab=readme-ov-file#handling-high-dpi-screens
   */
  const resizeCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const width = Math.min(
      Math.min(
        document.documentElement.clientWidth * widthPercentage || 0,
        window.innerWidth * widthPercentage || 0,
      ),
      maxWidth,
    );

    // Set display size (css pixels)
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    // Set actual size in memory (scaled to account for extra pixel density)
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;

    // Normalize coordinate system to use CSS pixels
    canvas.getContext('2d', { willReadFrequently: true })?.scale(ratio, ratio);

    // Clear the canvas
    handleClear();
  }, []);

  const handleClear = () => {
    signaturePadRef.current?.clear();
    setSignature(null);
    setIsCanvasEmpty(true);
  };

  const handleSave = () => {
    const { current: signaturePad } = signaturePadRef;
    if (!signaturePad || signaturePad.isEmpty()) return;
    const dataUrl = signaturePad.toDataURL();
    onSubmitSignature(dataUrl);
  };

  const createSignaturePad = useCallback(() => {
    if (canvasRef.current) {
      const sigPad = new _SignaturePad(canvasRef.current);
      signaturePadRef.current = sigPad;
    }
  }, []);

  // Set up event listeners for the signature pad on mount
  useEffect(() => {
    const { current: signaturePad } = signaturePadRef;

    if (!signaturePad) return;

    /** @see https://github.com/szimek/signature_pad/issues/147#issuecomment-191761079 */
    signaturePad.clear();

    // Hide placeholder when signing starts
    const handleBeginStroke = () => {
      setIsCanvasEmpty(false);
    };

    // Update the signature state when a stroke ends
    const handleEndStroke = () => {
      if (!signaturePad) return;
      setSignature(signaturePad.toDataURL());
    };

    signaturePad.addEventListener('beginStroke', handleBeginStroke);
    signaturePad.addEventListener('endStroke', handleEndStroke);
    return () => {
      signaturePad?.removeEventListener('beginStroke', handleBeginStroke);
      signaturePad?.removeEventListener('endStroke', handleEndStroke);
      signaturePad?.off();
    };
  }, []);

  // Create the signature pad and resize the canvas on initial render, avoiding flickering with useLayoutEffect
  useLayoutEffect(() => {
    createSignaturePad();
    resizeCanvas();
  }, [createSignaturePad, resizeCanvas]);

  useEffect(() => {
    window.addEventListener('resize', resizeCanvas);

    return () => window.removeEventListener('resize', resizeCanvas);
  }, [resizeCanvas]);

  // Disable signature pad when loading
  useEffect(() => {
    if (loading) {
      signaturePadRef.current?.off();
    } else {
      signaturePadRef.current?.on();
    }
  }, [loading]);
  return (
    <div className={cn('flex w-full justify-center', className)}>
      <div
        className="relative flex flex-col items-center justify-center"
        style={{ maxWidth: maxWidth, width: widthInVw }}
      >
        <div
          className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center bg-white"
          style={{ height: height }}
        >
          {isCanvasEmpty && (
            <span className="text-xl text-gray-400">
              {t('requisition_form.signing.signature_modal.placeholder')}
            </span>
          )}
        </div>
        <canvas
          id="signing-canvas"
          ref={canvasRef}
          className="z-20 border border-dashed border-gray-300"
        />
        <div className="flex w-full justify-between py-2">
          <Button
            disabled={!signature || loading}
            onClick={handleClear}
            size={'lg'}
          >
            {t('requisition_form.signing.signature_modal.clear')}
          </Button>
          <LoadingButton
            loading={loading}
            disabled={!signature}
            onClick={handleSave}
            size={'lg'}
          >
            {t('requisition_form.signing.signature_modal.sign')}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};
