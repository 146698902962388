import { forwardRef } from 'react';
import { Loader2 } from 'lucide-react';

import { Button, type ButtonProps } from '#components/Button/Button';

type LoadingButtonProps = {
  loading?: boolean;
} & ButtonProps;

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ loading, disabled, children, ...rest }, ref) => {
    return (
      <Button ref={ref} disabled={loading || disabled} {...rest}>
        {loading && <Loader2 className="mr-2 size-4 animate-spin" />}
        {children}
      </Button>
    );
  },
);
