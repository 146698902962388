import type { FetchOptions, FetchRequest } from 'ofetch';

import { baseFetch } from '@/lib/api-client';

// TODO: This shouldn't be a hook.

export const useCustomFetchPublic = () => {
  const fetchWithoutAccessToken = async (
    request: FetchRequest,
    fetchOptions?: FetchOptions,
  ) => {
    const res = await baseFetch(request, {
      ...fetchOptions,
    });
    return res;
  };

  const get = <T>(
    request: FetchRequest,
    fetchOptions?: FetchOptions,
  ): Promise<T> => fetchWithoutAccessToken(request, fetchOptions);

  const post = <T>(
    request: FetchRequest,
    body: FetchOptions['body'],
  ): Promise<T> => fetchWithoutAccessToken(request, { method: 'POST', body });

  return {
    get,
    post,
  };
};
