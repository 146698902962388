import type {
  GenerateRequisitionPdfResponseDto,
  GenerateRequisitionRequestDto,
} from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useGenerateRequisition = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof generateRequisition>;
} = {}) => {
  const { post } = useCustomFetch();

  function generateRequisition(dto: GenerateRequisitionRequestDto) {
    return post<GenerateRequisitionPdfResponseDto>(
      '/requisitions/generate-requisition',
      dto,
    );
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: generateRequisition,
  });
};
