import { nonEmptyString } from '@careos/types';
import { z } from 'zod';
import { RequesterInfoSchema } from '../../../../requester-info';
import { OrganizationTypeSchema } from '../../../organization-type';

export const UpdateEmployerRequestDtoSchema = z.object({
  employerKey: nonEmptyString,
  newEmployerName: nonEmptyString,
  organizationKey: nonEmptyString,
  organizationType: OrganizationTypeSchema,
});

export const UpdateEmployerWithRequesterInfoRequestDtoSchema =
  UpdateEmployerRequestDtoSchema.merge(RequesterInfoSchema);

export type UpdateEmployerRequestDto = z.infer<
  typeof UpdateEmployerRequestDtoSchema
>;

export type UpdateEmployerWithRequesterInfoRequestDto = z.infer<
  typeof UpdateEmployerWithRequesterInfoRequestDtoSchema
>;
