import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import type { User } from '@auth0/auth0-react';
import type {
  SamplingKitIdentifier,
  ToxicologyActivityIdentifier,
} from '@careos/identifiers';
import {
  GenerateRequisitionRequestDtoSchema,
  type Attester,
  type GenerateRequisitionRequestDto,
  type Patient,
  type Practitioner,
  type SpecimenInfoDoa,
  type SpecimenInfoPeth,
} from '@careos/toxicology-types';

import { assertDefined } from '@/utils/assert-defined';

import type { RequisitionFormDonorInfo } from '../components/requisition-form-donor-info';
import type { SamplingForm } from '../components/requisition-form-sampling';
import type { RequisitionFormTestInfo } from '../components/requisition-form-test-info';

type Props = {
  testInfo: RequisitionFormTestInfo;
  donorInfo: RequisitionFormDonorInfo;
  samplingInfo: SamplingForm;
  sessionId: string;
  user: User;
  locale: 'en-US' | 'sv-SE';
};

export const getGenerateRequisitionRequestDto = ({
  testInfo,
  donorInfo,
  samplingInfo,
  sessionId,
  user,
  locale,
}: Props): GenerateRequisitionRequestDto => {
  const patientFullName = `${donorInfo.firstName} ${donorInfo.lastName}`;
  const patient: Patient = {
    orderType: 'CoC',
    name: {
      given: [donorInfo.firstName],
      family: donorInfo.lastName,
      text: patientFullName,
    },
    identifier: {
      system: donorInfo.personalIdentifier.system,
      value: donorInfo.personalIdentifier.value.replaceAll('-', ''),
    },
    telecom: {
      system: 'phone',
      value: donorInfo.phoneNumber,
    },
  };

  const attester: Attester | undefined = donorInfo.attester.needsAttester
    ? {
        name: {
          given: [donorInfo.attester.firstName],
          family: donorInfo.attester.lastName,
          text: `${donorInfo.attester.firstName} ${donorInfo.attester.lastName}`,
        },
        identifier: {
          system: IdentifierSystem.PersonalIdentityNumber,
          value: donorInfo.attester.personalNumber.replaceAll('-', ''),
        },
      }
    : undefined;

  const name = assertDefined(user.name);
  const email = assertDefined(user.email);
  const nameArr = name.split(' ');
  const given = nameArr.slice(0, -1);
  const family = assertDefined(nameArr?.at(-1));

  const practitioner: Practitioner = {
    name: {
      given,
      family,
      text: name,
    },
    telecom: {
      system: 'email',
      value: email,
    },
  };

  const dto: Partial<GenerateRequisitionRequestDto> = {
    locale,
    barcodeValue: samplingInfo.barcode.value,
    samplingSessionId: sessionId,
    patient,
    attester,
    reason: testInfo.orderReason,
    comment: samplingInfo.comment.value,
    testType: testInfo.testInfo.testType,
    orderType: 'CoC',
    samplingLocation: {
      kind: 'workplace',
      display: testInfo.samplingLocation.name,
      organizationId: testInfo.samplingLocation.key,
    },
    practitioner,
    isChiralOrdered: false,
  };

  if (testInfo.testInfo.testType === 'DoA' && dto.testType === 'DoA') {
    if (!testInfo.testInfo.panelSize) {
      throw new Error('Undefined panel size for DoA.');
    }
    dto.panel = {
      size: testInfo.testInfo.panelSize,
      additionalObservations: testInfo.testInfo.additionalSubstances || [],
    };
    const { sampleType, samplingKit } = testInfo.testInfo;
    dto.specimenInfo = createDoaSpecimenInfo(sampleType, samplingKit);
  } else if (testInfo.testInfo.testType === 'PEth') {
    dto.specimenInfo = createPethSpecimenInfo();
  } else {
    throw new Error('Invalid test type');
  }

  const parsedDto = GenerateRequisitionRequestDtoSchema.parse(dto);
  return parsedDto;
};

function createDoaSpecimenInfo(
  sampleType: ToxicologyActivityIdentifier,
  samplingKit?: SamplingKitIdentifier | null,
): SpecimenInfoDoa {
  const sampledAt = new Date().toISOString();
  if (isUrineSampling(sampleType)) {
    return {
      specimenType: 'DOA_URINE_SAMPLING',
      sampledAt,
      urineTemperature: {
        high: 38,
        low: 32,
        unit: '°C',
      },
    };
  }
  if (!samplingKit) {
    throw new Error('Sampling kit is required');
  }
  if (isSalivaSampling(sampleType)) {
    return {
      specimenType: 'DOA_SALIVA_SAMPLING',
      sampledAt,
      samplingKit,
    };
  }
  if (isBloodSampling(sampleType)) {
    return {
      specimenType: 'DOA_BLOOD_SAMPLING',
      sampledAt,
      samplingKit,
    };
  }
  throw new Error('invalid specimen type');
}

export function createPethSpecimenInfo(): SpecimenInfoPeth {
  return {
    specimenType: 'PETH_DRIED_BLOOD_SPOTS',
    sampledAt: new Date().toISOString(),
    samplingKit: 'DRIED_BLOOD_SPOTS',
  };
}

const isUrineSampling = (specimenType: ToxicologyActivityIdentifier) =>
  specimenType === 'DOA_URINE_SAMPLING';

const isSalivaSampling = (specimenType: ToxicologyActivityIdentifier) =>
  specimenType === 'DOA_SALIVA_SAMPLING';

const isBloodSampling = (specimenType: ToxicologyActivityIdentifier) =>
  specimenType === 'DOA_BLOOD_SAMPLING';
