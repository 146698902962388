import { Alert, AlertDescription, AlertTitle } from '@careos/react-ui/Alert';
import { cn } from '@careos/react-ui/utils';
import { AlertCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type ErrorAlertProps = {
  title?: string;
  children?: React.ReactNode;
  className?: string;
};

export const ErrorAlert = ({ children, title, className }: ErrorAlertProps) => {
  const { t } = useTranslation();
  return (
    <Alert variant="destructive" className={cn('bg-red-50', className)}>
      <AlertCircle className="size-4" />
      <AlertTitle>{title ?? t('error.general')}</AlertTitle>
      {children && <AlertDescription>{children}</AlertDescription>}
    </Alert>
  );
};
