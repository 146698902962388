import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const SendSmsRequestDtoSchema = z.object({
  requisitionId: z.string().uuid(),
});

export class SendSmsRequestDto extends createSchemaClass(
  SendSmsRequestDtoSchema,
) {}
