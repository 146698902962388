import { Button } from '@careos/react-ui/Button';
import { cn } from '@careos/react-ui/utils';
import { ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const BackButton = ({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      variant="ghost"
      type="button"
      className={cn('flex gap-x-4', className)}
    >
      <ArrowLeft className="aspect-square" />
      <span>{t('requisition_form.back')}</span>
    </Button>
  );
};
