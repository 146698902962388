import { useAuth0 } from '@auth0/auth0-react';
import { Toaster } from '@careos/react-ui/Toast';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import React, { Suspense } from 'react';

import { Header } from '@/components/header/header';

const TanStackRouterDevtools =
  process.env.NODE_ENV !== 'development'
    ? () => null
    : React.lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

type TRouterContext = { auth: ReturnType<typeof useAuth0> };

export const Route = createRootRouteWithContext<TRouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  const { isAuthenticated, logout, user } = useAuth0();
  return (
    <>
      <div className="relative flex min-h-screen flex-col bg-[url('/background.svg')] bg-cover bg-no-repeat">
        {isAuthenticated && user && <Header onLogout={logout} user={user} />}
        <Toaster />
        <main>
          <Outlet />
        </main>
      </div>
      <Suspense>
        <TanStackRouterDevtools />
        <ReactQueryDevtools />
      </Suspense>
    </>
  );
}
