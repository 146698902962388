import { Button } from '@careos/react-ui/Button';
import { cn } from '@careos/react-ui/utils';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const NextButton = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  return (
    <Button type="submit" className={cn('flex', className)}>
      <div className="aspect-square h-full" />
      <span className="grow">{t('requisition_form.next')}</span>
      <ArrowRight className="aspect-square" />
    </Button>
  );
};
