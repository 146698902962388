import { Button } from '@careos/react-ui/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@careos/react-ui/Dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@careos/react-ui/Form';
import { Input } from '@careos/react-ui/Input';
import { nonEmptyString } from '@careos/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useRandomSamplingNumbers } from '../api/create-random-sampling-numbers-series';

type Props = {
  isGeneratorDialogOpen: boolean;
  onOpenChange: (open: boolean) => void;
};

const FormSchema = z.object({
  total_number_on_site: nonEmptyString,
  total_number_to_test: nonEmptyString,
});

export const NumberGenerator = ({
  isGeneratorDialogOpen,
  onOpenChange,
}: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'number_generator',
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      total_number_on_site: '',
      total_number_to_test: '',
    },
  });

  const {
    mutate: generateNumbers,
    status,
    data,
    error,
    reset: resetNumbersResponse,
  } = useRandomSamplingNumbers();

  function onSubmit(values: z.infer<typeof FormSchema>) {
    generateNumbers({
      maxNumber: Number(values.total_number_on_site),
      amountToGenerate: Number(values.total_number_to_test),
    });
  }

  return (
    <Dialog
      open={isGeneratorDialogOpen}
      onOpenChange={(open) => {
        form.reset();
        resetNumbersResponse();
        onOpenChange(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('title')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="total_number_on_site"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('employees_on_site')}</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="total_number_to_test"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('employees_to_test')}</FormLabel>
                  <FormControl>
                    <Input {...field} type="number" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button>{t('generate')}</Button>
          </form>
        </Form>
        <div>
          {status === 'error' && (
            <p className="text-red-500">
              {t('error')}: {error.message}
            </p>
          )}
          {status === 'success' && (
            <p className="rounded bg-slate-100 p-2">
              {data?.randomNumberSeries.join(' ')}
            </p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
