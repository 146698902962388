import type {
  StartSamplingSessionRequestDtoType,
  StartSamplingSessionResponseDto,
} from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import { queryClient, type MutationConfig } from '@/lib/react-query';

import { USE_SESSIONS_QUERY_KEY } from './get-sampling-sessions';

export const useCreateStartSamplingSession = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof createSamplingSession>;
} = {}) => {
  const { post } = useCustomFetch();

  function createSamplingSession(body: StartSamplingSessionRequestDtoType) {
    return post<StartSamplingSessionResponseDto>(
      '/sampling-session/start-sampling-session',
      body,
    );
  }

  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: [USE_SESSIONS_QUERY_KEY],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: createSamplingSession,
  });
};
