import { IdentifierSystem } from '@abc-labs-ab/ts-events';
import { TestType } from '@careos/organization-api-types';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@careos/react-ui/Card';
import type { DoaPanelSize, SamplingSession } from '@careos/toxicology-types';
import { useBlocker } from '@tanstack/react-router';
import { useState } from 'react';
import type { DeepPartial } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { CollectionOrderOptions } from '../api/get-order-options';
import {
  useMultipleStepForm,
  type Step,
} from '../hooks/use-multiple-step-form';

import { RequisitionFormDonorInfo } from './requisition-form-donor-info';
import {
  RequisitionFormSampling,
  type SamplingForm,
} from './requisition-form-sampling';
import { RequisitionFormSigning } from './requisition-form-signing';
import { RequisitionFormTestInfo } from './requisition-form-test-info';

const getInitialFormSamplingDetails = (
  samplingDetails: SamplingSession['samplingDetails'],
): RequisitionFormTestInfo['testInfo'] => {
  const doaSamplingDetails = samplingDetails.find(
    (it) => it.testType === 'DoA',
  );

  if (doaSamplingDetails) {
    const defaultValues: RequisitionFormTestInfo['testInfo'] = {
      testType: doaSamplingDetails.testType,
      samplingKit: doaSamplingDetails.samplingKit,
      sampleType: doaSamplingDetails.sampleType,
      panelSize: doaSamplingDetails.panelSize as Exclude<
        DoaPanelSize,
        'CHIRAL'
      >,
      additionalSubstances: [],
    };

    if (defaultValues.testType === 'DoA') {
      defaultValues.additionalSubstances =
        doaSamplingDetails.additionalSubstances || [];
    }

    return defaultValues;
  }

  return {
    testType: TestType.PEth,
  };
};
type Props = SamplingSession & {
  collectionOrderOptions: CollectionOrderOptions;
};
export const RequisitionForm = ({
  employer,
  reasonForTesting,
  samplingDetails,
  donor,
  collectionOrderOptions,
}: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form',
  });

  const { nextStep, currentStep, previousStep } = useMultipleStepForm();

  useBlocker({
    blockerFn: () => window.confirm(t('leave_warning')),
    condition: currentStep !== 'created',
  });

  const initialEmployer = collectionOrderOptions.employers.find(
    (it) => it.key === employer.key,
  );

  if (!initialEmployer) {
    throw new Error('Invalid employer key');
  }

  const [testInfoForm, setTestInfoForm] = useState<
    DeepPartial<RequisitionFormTestInfo>
  >({
    testInfo: getInitialFormSamplingDetails(samplingDetails),
    samplingLocation: initialEmployer,
    orderReason: reasonForTesting,
  });

  const handleTestInfoUpdate = (formValues: RequisitionFormTestInfo) => {
    setTestInfoForm((current) => ({
      ...current,
      ...formValues,
    }));
    nextStep();
  };

  const [donorInfoForm, setDonorInfoForm] = useState<RequisitionFormDonorInfo>({
    personalIdentifier: {
      system:
        donor?.personalIdentifier.system ??
        IdentifierSystem.PersonalIdentityNumber,
      value: donor?.personalIdentifier.value ?? '',
    },
    firstName: donor?.firstName ?? '',
    lastName: donor?.lastName ?? '',
    phoneNumber: donor?.phoneNumber ?? '',
    attester: {
      needsAttester: false,
    },
    collectorConfirm: false,
  });

  const handleDonorInfoUpdate = (
    donorInfo: Partial<RequisitionFormDonorInfo>,
    stepDirection: 'previous' | 'next',
  ) => {
    setDonorInfoForm({ ...donorInfoForm, ...donorInfo });
    if (stepDirection === 'next') {
      nextStep();
    } else {
      previousStep();
    }
  };

  const [samplingForm, setSamplingForm] = useState<Partial<SamplingForm>>({
    comment: {
      value: '',
      hasNoPrescription: false,
    },
    barcode: {
      value: '',
      confirmation: '',
    },
  });
  const handleSamplingUpdate = (
    samplingFormValues: Partial<SamplingForm>,
    stepDirection: 'previous' | 'next',
  ) => {
    setSamplingForm({ ...samplingForm, ...samplingFormValues });
    if (stepDirection === 'next') {
      nextStep();
    } else {
      previousStep();
    }
  };

  const renderStep = (currentStep: Step) => {
    switch (currentStep) {
      case 'test_info': {
        return (
          <RequisitionFormTestInfo
            {...collectionOrderOptions}
            formVals={testInfoForm}
            onUpdate={handleTestInfoUpdate}
          />
        );
      }
      case 'donor_info': {
        return (
          <RequisitionFormDonorInfo
            onUpdate={handleDonorInfoUpdate}
            defaultValues={donorInfoForm}
          />
        );
      }
      case 'sampling': {
        const testInfo = testInfoForm.testInfo;
        const isDoASampling = testInfo?.testType === 'DoA';
        const isSampleTypeUrine =
          isDoASampling && testInfo.sampleType === 'DOA_URINE_SAMPLING';
        return (
          <RequisitionFormSampling
            onUpdate={handleSamplingUpdate}
            formVals={samplingForm}
            isSampleTypeUrine={isSampleTypeUrine}
            isDoASampling={isDoASampling}
          />
        );
      }
      case 'created':
      case 'signing': {
        return (
          <RequisitionFormSigning
            onUpdateRequisitionStatus={nextStep}
            onBack={previousStep}
            testInfo={testInfoForm as RequisitionFormTestInfo}
            donorInfo={donorInfoForm}
            samplingInfo={samplingForm as SamplingForm}
          />
        );
      }
    }
  };

  return (
    <Card className="mx-auto w-fit">
      <CardHeader>
        <CardTitle className="mx-auto">{t(`${currentStep}.title`)}</CardTitle>
      </CardHeader>
      <CardContent>{renderStep(currentStep)}</CardContent>
    </Card>
  );
};
