import { Button } from '@careos/react-ui/Button';
import { Dialog, DialogContent, DialogTitle } from '@careos/react-ui/Dialog';
import type { SigningCompletionData } from '@careos/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { BankIdPoll } from '../hooks/use-bankid-polling';

import ScanQr from './scan-qr';

type ScanQRModalProps = Pick<BankIdPoll, 'onCompletion'> & {
  transactionId: string;
};

export const BankIdQR = ({ onCompletion, transactionId }: ScanQRModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'requisition_form.signing',
  });
  const [open, setOpen] = useState(false);

  const handleCompletion = async (data: SigningCompletionData) => {
    await new Promise((res) => setTimeout(res, 3000));
    onCompletion(data);
  };

  return (
    <>
      <Button className="w-full py-8" onClick={() => setOpen(true)}>
        {t('sign_with_bankid')}
      </Button>
      <Dialog open={open} onOpenChange={setOpen} modal>
        <DialogContent
          aria-describedby={undefined}
          onInteractOutside={(e) => e.preventDefault()}
          className="min-h-[500px] rounded-none p-0 sm:rounded-none"
        >
          <DialogTitle className="sr-only">{t('sign_with_bankid')}</DialogTitle>
          <ScanQr
            onCompletion={handleCompletion}
            onCancel={() => setOpen(false)}
            transactionId={transactionId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
