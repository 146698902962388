import { HintCode, type FailedResponseDto } from '@careos/types';
import { useTranslation } from 'react-i18next';

export type QRScanFailedActions = {
  onTryAgain: () => void;
  onCancel: () => void;
};

type QRScanFailedProps = QRScanFailedActions & FailedResponseDto;

export const QRScanFailed = ({
  hintCode,
  onCancel,
  onTryAgain,
}: QRScanFailedProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'bankid' });

  const hint =
    hintCode === 'unknown'
      ? t(`hintcode-qr-failed-${HintCode.unknown}`)
      : t(`hintcode-qr-${hintCode}`);

  return (
    <div className="flex flex-col gap-y-5 text-lg h-full justify-between">
      <p>{hint}</p>
      <div className="flex flex-col gap-y-5 h-full justify-self-end">
        <button className="bg-black px-8 py-4 text-white" onClick={onTryAgain}>
          {t('try-again')}
        </button>
        <button onClick={onCancel}>{t('cancel')}</button>
      </div>
    </div>
  );
};
