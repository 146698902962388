import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import type { MutationConfig } from '@/lib/react-query';

export const useCancelRequisitionRequest = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof cancelRequisitionRequest>;
} = {}) => {
  const { DELETE } = useCustomFetch();

  function cancelRequisitionRequest(transactionId: string) {
    return DELETE<void>(`/requisitions/requisition-request/${transactionId}`);
  }

  return useMutation({
    ...mutationConfig,
    mutationFn: cancelRequisitionRequest,
  });
};
