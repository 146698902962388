import { z } from 'zod';
import { RequesterInfoSchema } from '../../../requester-info';

export const UpdateUserMetadataRequestDtoSchema = z.object({
  metadata: z.record(z.string()),
});
export const UpdateUserMetadataWithRequesterInfoDtoSchema =
  UpdateUserMetadataRequestDtoSchema.merge(RequesterInfoSchema);

export type UpdateUserMetadataRequestDto = z.infer<
  typeof UpdateUserMetadataRequestDtoSchema
>;
export type UpdateUserMetadataWithRequesterInfoDto = z.infer<
  typeof UpdateUserMetadataWithRequesterInfoDtoSchema
>;
