import { z } from 'zod';
import {
  BarcodeSchema,
  CodeableConceptSchema,
  IdentifierSchema,
  IsoDateTimeSchema,
  ReferenceSchema,
} from '../fhir-entities';
import { createSchemaClass } from '../utils';
import { BaseEventSchema } from './base-event';

export const SpecimenRegisteredInLabEventSchema = BaseEventSchema.extend({
  eventType: z.literal('SpecimenRegisteredInLabEvent'),
  eventData: z.object({
    /**
     * When the specimen was received by the lab.
     */
    receivedAt: IsoDateTimeSchema,
    /**
     * {@link Barcode} of the Specimen that was registered.
     */
    barcode: BarcodeSchema,
    /**
     * SpecimenId, identifier for this unique specimen.
     */
    specimenId: z.string(),
    /**
     * The id of the backend object representing the parcel for the sample.
     */
    parcelId: z.string(),
    /**
     * The type of specimen, eg. blood, saliva
     */
    specimenType: CodeableConceptSchema,
    /**
     * The {@link Organization} which made the registration
     */
    organization: ReferenceSchema(z.literal('Organization')),
    /**
     * The {@link Location} of where the specimen was registered
     */
    location: ReferenceSchema(z.literal('Location')).optional(),
    /**
     * {@link Practitioner} who registered the specimen
     */
    practitioner: ReferenceSchema(z.literal('Practitioner')),
    /**
     * {@link SamplingKit} of specimen
     */
    samplingKit: IdentifierSchema.optional(),
    /**
     * The id of the linked specimen, if any.
     */
    linkedSpecimenId: z.string().optional(),
    /**
     * Id of the rack where the specimen is stored.
     */
    rackId: z.string().optional(),
  }),
});

/**
 * A sample has been registered as received by a lab.
 */
export class SpecimenRegisteredInLabEvent extends createSchemaClass(
  SpecimenRegisteredInLabEventSchema,
) {}

export type SpecimenRegisteredInLabEventType = z.infer<
  typeof SpecimenRegisteredInLabEventSchema
>;
