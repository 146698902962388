import { createSchemaClass } from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const EndUserSigningDataSchema = z.object({
  personalNumber: z.string().optional(),
  endUserAgent: z.string().optional(),
  endUserIp: z.string().optional(),
});

export type EndUserSigningDataDto = z.infer<typeof EndUserSigningDataSchema>;

export const SignRequestSchema = EndUserSigningDataSchema.merge(
  z.object({
    visibleText: z.string(),
    hash: z.string(),
    returnUrl: z.string().optional(),
  }),
);

export class SignRequestDto extends createSchemaClass(SignRequestSchema) {}
