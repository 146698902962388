import {
  FollowUpAnalysisTypeSchema,
  FollowUpAnalysisStatusSchema,
} from '@abc-labs-ab/ts-events';
import { z } from 'zod';

export const ExtraResultStatusSchema = z.enum([
  ...FollowUpAnalysisStatusSchema.options,
  'not-ordered',
  'ordered',
  'reported',
]);
export type ExtraResultStatus = z.infer<typeof ExtraResultStatusSchema>;
export const ExtraResultStatus = ExtraResultStatusSchema.Values;

export const ChiralExpectedExtraResultReasonSchema = z.enum([
  'AMPHETAMINE_POSITIVE_AND_METHAMPHETAMINE_NEGATIVE',
]);
export type ChiralExpectedExtraResultReason = z.infer<
  typeof ChiralExpectedExtraResultReasonSchema
>;

export const ChiralAbandonedExtraResultReasonSchema = z.enum([
  'AMPHETAMINE_CONCENTRATION_LOW',
  'AMPHETAMINE_NEGATIVE',
  'METHAMPHETAMINE_POSITIVE',
]);

export type ChiralAbandonedExtraResultReason = z.infer<
  typeof ChiralAbandonedExtraResultReasonSchema
>;

export const ChiralAbandonedExtraResultReason =
  ChiralAbandonedExtraResultReasonSchema.Values;

export const ChiralExtraResultReasonSchema = z.enum([
  ...ChiralExpectedExtraResultReasonSchema.options,
  ...ChiralAbandonedExtraResultReasonSchema.options,
]);

export const ExtraResultReasonSchema = z.enum([
  ...ChiralExtraResultReasonSchema.options,
]);

export type ExtraResultReason = z.infer<typeof ExtraResultReasonSchema>;

export const ExtraResultSchema = z.object({
  type: FollowUpAnalysisTypeSchema,
  status: ExtraResultStatusSchema,
  reason: ExtraResultReasonSchema.nullish(),
});
export type ExtraResult = z.infer<typeof ExtraResultSchema>;

export const isChiralAbandonedExtraResultReason = (
  reason: unknown,
): reason is ChiralAbandonedExtraResultReason =>
  ChiralAbandonedExtraResultReasonSchema.safeParse(reason).success;
