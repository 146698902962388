import { HintCode, type PendingResponseDto } from '@careos/types';
import { QRCode } from './QRCode';
import { useTranslation } from 'react-i18next';

export const QRScanPending = ({ hintCode, qrText }: PendingResponseDto) => {
  const { t } = useTranslation('translation', { keyPrefix: 'bankid' });

  const hint =
    hintCode === 'unknown'
      ? t(`hintcode-qr-pending-${HintCode.unknown}`)
      : t(`hintcode-qr-${hintCode}`);

  if (hintCode === 'userSign') {
    return <p>{t('hintcode-qr-userSign')}</p>;
  }

  return (
    <>
      <p className="mb-4">{hint}</p>
      <QRCode qrCode={qrText} />
    </>
  );
};
