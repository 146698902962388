import type {
  FinishSamplingSessionRequestDtoType,
  FinishSamplingSessionResponseDto,
} from '@careos/toxicology-types';
import { useMutation } from '@tanstack/react-query';

import { useCustomFetch } from '@/hooks/custom-fetch';
import { queryClient, type MutationConfig } from '@/lib/react-query';

import { USE_SESSIONS_QUERY_KEY } from './get-sampling-sessions';

export const useCreateFinishSession = ({
  mutationConfig,
}: {
  mutationConfig?: MutationConfig<typeof createSamplingSession>;
} = {}) => {
  const { post } = useCustomFetch();

  function createSamplingSession(body: FinishSamplingSessionRequestDtoType) {
    return post<FinishSamplingSessionResponseDto>(
      '/sampling-session/finish-sampling-session',
      body,
    );
  }

  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: [USE_SESSIONS_QUERY_KEY],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: createSamplingSession,
  });
};
